import React from 'react'
import { graphql } from 'gatsby'
import ContentBlock from '../components/modules/ContentBlock'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import { MaxWidthContainer } from '../styles/mixins'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/modules/Hero'

export const query = graphql`
  query AboutPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      _id
      id
      header
      _rawBody
			_rawExtendedContent
			mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
			cta {
				title
				href
				target
			}
    }
  }
`

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page
	console.log("Page: ", page)
  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.header} />
      <Container>
        <Hero page={page} />
        <MaxWidthContainer maxW="1100px">
					<ContentBlock content={page._rawExtendedContent} />
				</MaxWidthContainer>
      </Container>
    </Layout>
  )
}

export default AboutPage
